/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateGivingPreferenceDto } from '../models/CreateGivingPreferenceDto';
import type { CreateSubscriptionDto } from '../models/CreateSubscriptionDto';
import type { GivingPreferenceDto } from '../models/GivingPreferenceDto';
import type { SubscriptionDto } from '../models/SubscriptionDto';
import type { UpdateGivingPreferenceDto } from '../models/UpdateGivingPreferenceDto';
import type { UpdateSubscriptionDto } from '../models/UpdateSubscriptionDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AutoGivingService {
  /**
   * Create subscription
   * @param requestBody
   * @returns SubscriptionDto Successfully created subscription
   * @throws ApiError
   */
  public static subscriptionCreateSubscriptionV5(
    requestBody: CreateSubscriptionDto,
  ): CancelablePromise<SubscriptionDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/auto-giving/subscription',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Get subscription
   * @param givingPlanId Subscription giving plan id
   * @param userId Subscription user id
   * @returns SubscriptionDto Successfully retrieved subscription
   * @throws ApiError
   */
  public static subscriptionGetSubscriptionV5(
    givingPlanId: string,
    userId: string,
  ): CancelablePromise<SubscriptionDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auto-giving/subscription',
      query: {
        givingPlanId: givingPlanId,
        userId: userId,
      },
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Update subscription
   * @param requestBody
   * @returns SubscriptionDto Successfully updated subscription
   * @throws ApiError
   */
  public static subscriptionUpdateSubscriptionV5(
    requestBody: UpdateSubscriptionDto,
  ): CancelablePromise<SubscriptionDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v5/auto-giving/subscription',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Create giving preference
   * @param requestBody
   * @returns GivingPreferenceDto Successfully created giving preference
   * @throws ApiError
   */
  public static givingPreferenceCreateGivingPreferenceV5(
    requestBody: CreateGivingPreferenceDto,
  ): CancelablePromise<GivingPreferenceDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/auto-giving/giving-preference',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Update giving preference
   * @param requestBody
   * @returns GivingPreferenceDto Successfully updated giving preference
   * @throws ApiError
   */
  public static givingPreferenceUpdateGivingPreferenceV5(
    requestBody: UpdateGivingPreferenceDto,
  ): CancelablePromise<GivingPreferenceDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v5/auto-giving/giving-preference',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Get giving preference
   * @param userId Giving preference user id
   * @returns GivingPreferenceDto Successfully received giving preference
   * @throws ApiError
   */
  public static givingPreferenceGetGivingPreferenceV5(
    userId: string,
  ): CancelablePromise<Array<GivingPreferenceDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auto-giving/giving-preference',
      query: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
}
