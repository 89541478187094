import { UserPayload } from '@/src/components/AdyenAddCard/AdyenAddCard.interface';
import { OpenAPI } from '@/src/lib/api/v4';
import { request } from '@/src/lib/api/v4/client/core/request';
import {
  CardsResponse,
  GivingPreference,
  Preference,
  PreferencePostRequest,
  Subscription,
  SubscriptionPlan,
  SubscriptionPlanUpdate,
  UserPreferences,
  Card,
} from '@/src/types';

export class UserPreferencesService {
  public static getUserPreferences = async (
    userId: string,
  ): Promise<Preference[]> => {
    return await request(OpenAPI, {
      method: 'GET',
      url: '/v3/api/user/properties',
      query: { userId },
    });
  };

  public static setUserPreference = async (
    preference: PreferencePostRequest,
  ): Promise<Preference> => {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/properties',
      body: preference,
    });
  };

  /**
   * getCards Function
   *
   * Overview:
   * The getCards function is an asynchronous function responsible for fetching
   * a user's card information from an API. It makes a POST request to the API
   * with the user's email and the action verb.
   *
   * Parameters:
   * 1. requestBody: An object containing:
   *    - email (string): The email address of the user whose card data is to
   * be fetched.
   *    - verb (string): The action verb, typically describing the type of
   * operation or request (e.g., 'list' for listing cards).
   *
   * Returns:
   * - Promise<CardsResponse>: A promise that resolves to the response from the
   * API. The response is expected to be of the type CardsResponse, which
   * should encapsulate the card data returned from the API.
   *
   */
  public static async getCards({
    requestBody,
  }: {
    requestBody: {
      email?: string;
      verb: string;
    };
  }): Promise<CardsResponse> {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/cards',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async createSubscription({
    requestBody,
  }: {
    requestBody: SubscriptionPlan;
  }): Promise<UserPreferences> {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v5/auto-giving/subscription',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async createGivingPreference({
    requestBody,
  }: {
    requestBody: GivingPreference;
  }): Promise<UserPreferences> {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v5/auto-giving/giving-preference',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async getSubscription({
    params,
  }: {
    params: string;
  }): Promise<Subscription> {
    return await request(OpenAPI, {
      method: 'GET',
      url: `/v5/auto-giving/subscription?${params}`,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async updateSubscription({
    requestBody,
  }: {
    requestBody: SubscriptionPlanUpdate;
  }): Promise<UserPreferences> {
    return await request(OpenAPI, {
      method: 'PUT',
      url: '/v5/auto-giving/subscription',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async getGivingPreferences({
    params,
  }: {
    params: string;
  }): Promise<GivingPreference> {
    return await request(OpenAPI, {
      method: 'GET',
      url: `/v5/auto-giving/giving-preference?${params}`,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async updateGivingPreferences({
    requestBody,
  }: {
    requestBody: GivingPreference;
  }): Promise<GivingPreference> {
    return await request(OpenAPI, {
      method: 'PUT',
      url: '/v5/auto-giving/giving-preference',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async addCard({
    requestBody,
  }: {
    requestBody: UserPayload;
  }): Promise<Card> {
    return await request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/cards',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }

  public static async logout() {
    const res = await request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/logout',
      mediaType: 'application/json',
      errors: {
        403: `The requested action is not permitted for the current user`,
        404: `The requested entity or resource was not found`,
      },
    });
  }
}
