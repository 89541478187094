/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class DefaultService {
  /**
   * @returns any
   * @throws ApiError
   */
  public static statusGetStatus(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/',
    });
  }
  /**
   * (UNSTABLE) Google Social Login in the 5.0 system
   * @returns any
   * @throws ApiError
   */
  public static authGoogleAuthV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auth/google',
    });
  }
  /**
   * (UNSTABLE) Google callback api to return user data in order to obtain a bearer token
   * @returns any
   * @throws ApiError
   */
  public static authGoogleAuthRedirectV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auth/google/callback',
    });
  }
  /**
   * (UNSTABLE) Facebook Social Login in the 5.0 system
   * @returns any
   * @throws ApiError
   */
  public static authFacebookAuthV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auth/facebook',
    });
  }
  /**
   * (UNSTABLE) Facebook callback api to return user data in order to obtain a bearer token
   * @returns any
   * @throws ApiError
   */
  public static authFacebookAuthRedirectV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/auth/facebook/redirect',
    });
  }
}
