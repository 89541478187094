/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CardTokenDto } from '../models/CardTokenDto';
import type { LegacyCardResponseDto } from '../models/LegacyCardResponseDto';
import type { QueryDto } from '../models/QueryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class CardService {
  /**
   * Save card token for specified card
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static chargeSaveCardTokenV5(
    requestBody: CardTokenDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/card/save-token',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Get list of all cards of user
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static chargeGetUserCardListV5(
    requestBody: QueryDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/card',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Delete specified card
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static chargeDeleteCardV5(
    requestBody: QueryDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v5/card',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Get cards from legacy database for user
   * @param userId User id
   * @returns LegacyCardResponseDto Successfully retrieved charges
   * @throws ApiError
   */
  public static chargeGetLegacyUserCardListV5(
    userId: string,
  ): CancelablePromise<Array<LegacyCardResponseDto>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v5/card/legacy-list',
      query: {
        userId: userId,
      },
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
  /**
   * Set specified card as default
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static chargeSetDefaultCardV5(
    requestBody: QueryDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/card/default',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        401: `Unauthorized access.`,
      },
    });
  }
}
