/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Buffer } from '../models/Buffer';
import type { OrganizationDonationDto } from '../models/OrganizationDonationDto';
import type { TaxDeductibleSummaryDto } from '../models/TaxDeductibleSummaryDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class TaxService {
  /**
   * Download tax statement
   * @param year
   * @param country
   * @param donationGroupBy
   * @param contentType
   * @param accept
   * @returns Buffer Successful response
   * @throws ApiError
   */
  public static taxDownloadTaxStatementV4(
    year: number,
    country: string,
    donationGroupBy: 'individual' | 'grouped',
    contentType: 'text/csv' | 'application/vnd.ms-excel' | 'application/pdf',
    accept: 'text/csv' | 'application/vnd.ms-excel' | 'application/pdf',
  ): CancelablePromise<Buffer> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/tax/statement',
      headers: {
        'Content-type': contentType,
        Accept: accept,
      },
      query: {
        year: year,
        country: country,
        donationGroupBy: donationGroupBy,
      },
    });
  }
  /**
   * Retrieve tax deductible amount
   * @param year
   * @param country
   * @returns number Successful response
   * @throws ApiError
   */
  public static taxGetTaxDeductibleDonationsV4(
    year: number,
    country: string,
  ): CancelablePromise<number> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/tax/donations/deductible',
      query: {
        year: year,
        country: country,
      },
    });
  }
  /**
   * Retrieve tax deductible summary
   * @param year
   * @param country
   * @returns TaxDeductibleSummaryDto Successful response
   * @throws ApiError
   */
  public static taxGetTaxDeductibleSummaryV4(
    year: number,
    country: string,
  ): CancelablePromise<TaxDeductibleSummaryDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/tax/donations-summary',
      query: {
        year: year,
        country: country,
      },
    });
  }
  /**
   * Retrieve tax eligible donations
   * @param year
   * @param country
   * @returns OrganizationDonationDto Successful response
   * @throws ApiError
   */
  public static taxGetTaxEligibleDonationsV4(
    year: number,
    country: string,
  ): CancelablePromise<OrganizationDonationDto[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/tax/tax-eligible-donations',
      query: {
        year: year,
        country: country,
      },
    });
  }
  /**
   * Retrieve tax ineligible donations
   * @param year
   * @param country
   * @returns OrganizationDonationDto Successful response
   * @throws ApiError
   */
  public static taxGetTaxIneligibleDonationsV4(
    year: number,
    country: string,
  ): CancelablePromise<OrganizationDonationDto[]> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/tax/tax-ineligible-donations',
      query: {
        year: year,
        country: country,
      },
    });
  }
}
