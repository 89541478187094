/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UtilService {
  /**
   * Echo back query params
   * @returns any
   * @throws ApiError
   */
  public static echoEchoGet(): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/echo',
    });
  }
  /**
   * Echo back request body
   * @returns any
   * @throws ApiError
   */
  public static echoEchoPost(): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/echo',
    });
  }
  /**
   * Echo back request body
   * @returns any
   * @throws ApiError
   */
  public static echoEchoPut(): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/echo',
    });
  }
  /**
   * Echo back request body
   * @returns any
   * @throws ApiError
   */
  public static echoEchoPatch(): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/echo',
    });
  }
}
