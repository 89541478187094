/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { RolesDto } from '../models/RolesDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class RoleService {
  /**
   * Add role(s) to a user in the 4.0 system
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userAddRoleV5(
    requestBody: RolesDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/user/role',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Remove role(s) from a user in the 4.0 system
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userRemoveRoleV5(
    requestBody: RolesDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v5/user/role',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
