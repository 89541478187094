/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BeneficiaryManualResetDto } from '../models/BeneficiaryManualResetDto';
import type { BeneficiaryStatusDto } from '../models/BeneficiaryStatusDto';
import type { CreateIndividualAddressDto } from '../models/CreateIndividualAddressDto';
import type { CreateIndividualBeneficiaryDto } from '../models/CreateIndividualBeneficiaryDto';
import type { CreateIndividualDocumentDto } from '../models/CreateIndividualDocumentDto';
import type { CreateIndividualDto } from '../models/CreateIndividualDto';
import type { UpdateIndividualAddressDto } from '../models/UpdateIndividualAddressDto';
import type { UpdateIndividualBeneficiaryDto } from '../models/UpdateIndividualBeneficiaryDto';
import type { UpdateIndividualDto } from '../models/UpdateIndividualDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class BeneficiaryService {
  /**
   * create individual beneficiary
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static beneficiaryCreateIndividualBeneficiaryV5(
    requestBody: CreateIndividualBeneficiaryDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * update individual beneficiary
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static beneficiaryUpdateIndividualBeneficiaryV5(
    requestBody: UpdateIndividualBeneficiaryDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v5/beneficiary/update',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * manually review beneficiary
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static beneficiaryManualReviewV5(
    requestBody: BeneficiaryStatusDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary/manual-review',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * block reject beneficiary
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static beneficiaryBlockRejectV5(
    requestBody: BeneficiaryStatusDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary/block-reject',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * manually reset beneficiary
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static beneficiaryManualResetV5(
    requestBody: BeneficiaryManualResetDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary/manual-reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static addressCreateV5(
    requestBody: CreateIndividualAddressDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary/address/individual',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static addressUpdateV5(
    requestBody: UpdateIndividualAddressDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v5/beneficiary/address/individual',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static documentUploadIndividualDocumentV4(
    requestBody: CreateIndividualDocumentDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v4/beneficiary/document/individual',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static individualCreateV5(
    requestBody: CreateIndividualDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/beneficiary/individual',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static individualUpdateV5(
    requestBody: UpdateIndividualDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v5/beneficiary/individual',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
