/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class SettlementService {
  /**
   * Receive a notification
   * @returns any
   * @throws ApiError
   */
  public static settlementNotificationV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/settlement',
    });
  }
}
