import { request as requestv4 } from '@/src/lib/api/v4/client/core/request';
import { OpenAPI as OpenAPIv4 } from '@/src/lib/api';
import { GivingPlanCategoryType, GivingPlanWithProperties } from '@/src/types';

export class GivingPlanService {
  public static async getGivingPlanByCampaignId(
    campaignId: number,
  ): Promise<GivingPlanWithProperties[]> {
    return requestv4(OpenAPIv4, {
      method: 'GET',
      url: '/v4/giving-plans',
      query: {
        category: 'CHARITY',
        campaignId,
      },
      mediaType: 'application/json',
      errors: {
        400: `Unsuccessful`,
      },
    });
  }

  public static async getAllGivingPlans(
    category?: GivingPlanCategoryType,
  ): Promise<GivingPlanWithProperties[]> {
    return requestv4(OpenAPIv4, {
      method: 'GET',
      url: '/v4/giving-plans',
      query: {
        category,
      },
      mediaType: 'application/json',
      errors: {
        400: `Unsuccessful`,
      },
    });
  }
}
