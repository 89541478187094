/* eslint-disable import/export */
import config from '@/src/config';
import { OpenAPI as OpenAPIV4 } from './v4/client';
import { OpenAPI as OpenAPIV3 } from './v3/client';

OpenAPIV3.BASE = config().lg3BaseUrl;
OpenAPIV4.BASE = config().lg4ApiBaseUrl;

// base config is moved down to this file so it can work with server side rendering.

export * from './v3';
export * from './v4';

// re-exporting these explicitly because they exist in v3 as well
export {
  AccountService,
  ApiError,
  AuthService,
  CancelError,
  CancelablePromise,
  OpenAPI,
  type OpenAPIConfig,
  UserService,
} from './v4';
