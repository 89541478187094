/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class NotificationService {
  /**
   * manually notification for competition-winners
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static notificationNotificationForCompetitionWinnersV5(
    requestBody: Array<string>,
  ): CancelablePromise<Array<Record<string, any>>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/notification/competition-winners',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
