/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OtpDto } from '../models/OtpDto';
import type { OtpSessionEntity } from '../models/OtpSessionEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class ProxyService {
  /**
   * Obtain current session using an OTP
   * @param requestBody
   * @returns OtpSessionEntity
   * @throws ApiError
   */
  public static lg3ProxyLoginWithSharedTokenV3(
    requestBody: OtpDto,
  ): CancelablePromise<OtpSessionEntity> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/loginWithSharedToken',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestGet(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestPost(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestPut(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestDelete(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestPatch(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'PATCH',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestOptions(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'OPTIONS',
      url: '/v3/api/*',
    });
  }
  /**
   * Proxy any GET request to the V3 API
   * @returns any
   * @throws ApiError
   */
  public static lg3ProxyProxyRequestHead(): CancelablePromise<
    Record<string, any>
  > {
    return __request(OpenAPI, {
      method: 'HEAD',
      url: '/v3/api/*',
    });
  }
}
