/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Campaign } from '../models/Campaign';
import type { PayoutDetails } from '../models/PayoutDetails';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PayoutService {
  /**
   * Returns payout details
   * @param id The ID of the payout
   * @returns PayoutDetails Successful response
   * @throws ApiError
   */
  public static payoutGetPayoutV4(
    id: number,
  ): CancelablePromise<PayoutDetails> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/payouts/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * Returns campaigns of the provided payoutId
   * @param id The payoutId of the campaigns
   * @returns Campaign Successful response
   * @throws ApiError
   */
  public static payoutGetPayoutCampaignsV4(
    id: number,
  ): CancelablePromise<Array<Campaign>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/payouts/{id}/campaigns',
      path: {
        id: id,
      },
    });
  }
  /**
   * Download payout details
   * @param id The payout id of the campaigns
   * @param accept
   * @param contentType
   * @returns string Successful response
   * @throws ApiError
   */
  public static payoutDownloadPayoutDetailsV4(
    id: number,
    accept: 'text/csv' | 'application/vnd.ms-excel',
    contentType: 'text/csv' | 'application/vnd.ms-excel',
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/payouts/{id}/download',
      path: {
        id: id,
      },
      headers: {
        Accept: accept,
        'Content-type': contentType,
      },
      responseHeader: 'Content-Disposition',
      errors: {
        400: `Bad request`,
      },
    });
  }
}
