/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CompetitionManageDto } from '../models/CompetitionManageDto';
import type { CompetitionRankedStatsFilteredDto } from '../models/CompetitionRankedStatsFilteredDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class LeaderboardService {
  /**
   * List Competitions (optionally filtered by name and seasonType)
   * @param skip
   * @param take
   * @param name
   * @param seasonType
   * @returns any
   * @throws ApiError
   */
  public static competitionFindCompetitionsV4(
    skip: number,
    take: number,
    name: string,
    seasonType: string,
  ): CancelablePromise<Array<Record<string, any>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions',
      query: {
        skip: skip,
        take: take,
        name: name,
        seasonType: seasonType,
      },
    });
  }
  /**
   * Create a Competition
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static competitionCreateCompetitionV4(
    requestBody: CompetitionManageDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v4/competitions',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Get a Competition by ID
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static competitionGetCompetitionV4(
    id: number,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * Update a Competition
   * @param id
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static competitionUpdateCompetitionV4(
    id: number,
    requestBody: CompetitionManageDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v4/competitions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Gets the competition ranked stats for a given competition by ID
   * @param id
   * @param type
   * @returns any
   * @throws ApiError
   */
  public static competitionGetIndividualCompetitionRankedStatsV4(
    id: number,
    type: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions/{id}/ranked-stats/individual/{type}',
      path: {
        id: id,
        type: type,
      },
    });
  }
  /**
   * Gets the competition ranked stats for a given competition by ID
   * @param id
   * @param type
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static competitionGetIndividualCompetitionRankedStatsFilteredV4(
    id: number,
    type: string,
    requestBody: CompetitionRankedStatsFilteredDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v4/competitions/{id}/ranked-stats/individual/{type}',
      path: {
        id: id,
        type: type,
      },
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Get CompetitioGroup By ID
   * @param id
   * @returns any
   * @throws ApiError
   */
  public static competitionGetCompetitionGroupV4(
    id: number,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions/groups/{id}',
      path: {
        id: id,
      },
    });
  }
  /**
   * Get Total Winnings By CompetitionGroupIDs and AccountID
   * @param groupIds Competition Group Ids to filter by
   * @param accountId
   * @returns any
   * @throws ApiError
   */
  public static competitionGetCompetitionTotalWinningsV4(
    groupIds: Array<number>,
    accountId: number,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions/total-winnings/accounts/{accountId}',
      path: {
        accountId: accountId,
      },
      query: {
        groupIds: groupIds,
      },
    });
  }
  /**
   * Get Daily Winnings By CompetitionGroupIDs and AccountID
   * @param groupIds Competition Group Ids to filter by
   * @param accountId
   * @returns any
   * @throws ApiError
   */
  public static competitionGetCompetitionDailyWinningsV4(
    groupIds: Array<number>,
    accountId: number,
  ): CancelablePromise<Array<Record<string, any>>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/competitions/daily-winnings/accounts/{accountId}',
      path: {
        accountId: accountId,
      },
      query: {
        groupIds: groupIds,
      },
    });
  }
}
