/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateUserDto } from '../models/CreateUserDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class UserService {
  /**
   * Create a new user in the 5.0 system
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userCreateUserV5(
    requestBody: CreateUserDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/user',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Remove user in the 4.0 system
   * @param email
   * @returns any
   * @throws ApiError
   */
  public static userRemoveUserV5(
    email: string,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v5/user/{email}',
      path: {
        email: email,
      },
    });
  }
}
