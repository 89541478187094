/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Lg3UserSession } from '../models/Lg3UserSession';
import type { LoginDto } from '../models/LoginDto';
import type { OtpDto } from '../models/OtpDto';
import type { OtpSessionEntity } from '../models/OtpSessionEntity';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class AuthService {
  /**
   * Log in using an email/password combination to obtain a bearer token
   * @param requestBody
   * @returns Lg3UserSession
   * @throws ApiError
   */
  public static authLoginWithEmailPasswordV4(
    requestBody: LoginDto,
  ): CancelablePromise<Lg3UserSession> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v4/auth/email-password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Log out user by deleting user auth info in Redis and updating 3.0 database of logout state
   * @returns void
   * @throws ApiError
   */
  public static authLogoutV4(): CancelablePromise<void> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v4/auth',
    });
  }
  /**
   * Get information about the current user identified by the bearer token
   * @returns Lg3UserSession
   * @throws ApiError
   */
  public static authGetSessionV4(): CancelablePromise<Lg3UserSession> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/auth/session',
    });
  }
  /**
   * (UNSTABLE) Login user in the 5.0 system using an email/password combination to obtain a bearer token
   * Set bearer token in local storage by loading an HTML page in the browser
   * @param authorization
   * @returns string
   * @throws ApiError
   */
  public static authGetCrossDomainClientV4(
    authorization: string,
  ): CancelablePromise<string> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v4/auth/iframe',
      query: {
        authorization: authorization,
      },
    });
  }
  /**
   * Login user in the 5.0 system using an email/password combination to obtain a bearer token
   * @returns any
   * @throws ApiError
   */
  public static authLoginV5(): CancelablePromise<any> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/auth/email-password',
    });
  }
  /**
   * (UNSTABLE) Check whether a V5 bearer token is valid
   * @returns any
   * @throws ApiError
   */
  public static authValidateTokenV5(): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/auth/validate-token',
    });
  }
  /**
   * Obtain current session using an OTP
   * @param requestBody
   * @returns OtpSessionEntity
   * @throws ApiError
   */
  public static lg3ProxyLoginWithSharedTokenV3(
    requestBody: OtpDto,
  ): CancelablePromise<OtpSessionEntity> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v3/api/user/loginWithSharedToken',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
