/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ResetPasswordDto } from '../models/ResetPasswordDto';
import type { SubmitResetPasswordDto } from '../models/SubmitResetPasswordDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';
export class PasswordService {
  /**
   * Reset password request in the 4.0 system
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userResetPasswordRequestV5(
    requestBody: ResetPasswordDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/user/password-reset',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * Update password using reset token in the 4.0 system
   * @param requestBody
   * @returns any
   * @throws ApiError
   */
  public static userUpdatePasswordV5(
    requestBody: SubmitResetPasswordDto,
  ): CancelablePromise<Record<string, any>> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v5/user/password',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
